import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import {
  Section,
  BreadCrumb,
  TermCondition,
  GradientWrapper,
  SectionTitle,
} from "../components/Section";
import { ArrowRight, HomeIcon } from "../components/Icons";
import Congratulation from "../components/Congratulations";
import ActivationForm from "../sections/barn-bonus-plus/activation-form";
import CheckOrange from "../images/check-orange.svg";
import checkImage from "../images/icon-check.svg";
import Image from "../components/image";
import CustomerReviewSlider from "../sections/barn-bonus-plus/customer-slider";
import BarnBonusPlusBenifits from "../sections/barn-bonus-plus/barn-bonus-plus-benefits";
import BreakpointDown from "../components/Media/BreakpointDown";

const TermConditions = styled(TermCondition)`
  ${BreakpointDown.lg`
  margin-bottom:0px; 
`}
`;

const BarnBenifts = styled.div`
  margin-top: -250px;
`;
const BarnBeniftsInner = styled.div`
  border: 1px solid #cbd1e2;
  display: flex;
  position: relative;
  ${BreakpointDown.lg`
   flex-direction:column;
 `}
`;
const BarnBeniftsInnerItem = styled.div`
  flex: 1;
  padding: 40px;
  position: relative;
  ${BreakpointDown.lg`
    padding:40px 20px;
  `}
  &:last-child {
    padding-left: 70px;
    ${BreakpointDown.lg`
    padding:50px 20px 40px; 
   `}
  }
  ul {
    li {
      &:before {
        background: url(${CheckOrange});
      }
    }
  }
  &:first-child {
    border-right: 1px solid #cbd1e2;
    ${BreakpointDown.lg`
      border-bottom:1px solid #CBD1E2;
      border-right:0;
    `}
  }
`;
const BarnBeniftsInnerHeading = styled.h2`
  font-size: 24px;
  line-height: 35px;
`;

const ListItemWrapperLeft = styled.div`
  max-width: 450px;
`;
const ListItemWrapperRight = styled.div`
  max-width: 500px;
  ul {
    li {
      &:before {
        background: url(${checkImage});
        background-repeat: no-repeat;
        top: 5px;
      }
    }
  }
`;
const PlusCircle = styled.div`
  width: 70px;
  height: 70px;
  max-width: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 100%;
  margin-left: -35px;
  background: #fff;
  transform: translateY(-50%);
  position: absolute;
  border: 1px solid #cbd1e2;
  ${BreakpointDown.lg`
    width:60px;
    height:60px;
    top:100%;
    left:50%;
  `}
`;
const ImgWrapper = styled.div`
  width: 100%;
  max-width: 25px;
  position: relative;
`;

// Activation Barn Bonus Plus Form
function BanBonusPlusPage(queryData) {
  let pageData = queryData.data.contentfulBarnBonusPage;
  let barnReviews = queryData.data.allContentfulReview;

  return (
    <Layout pageName="barn-bonus-plus">
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <Section
        bgColor="rgba(203, 209, 226, 0.55)"
        pt="180px"
        xpt="60px"
        pb="300px"
      >
        <BreadCrumb top="75" xtop="15px">
          <div className="container">
            <Link to="/">
              <HomeIcon /> <ArrowRight />
            </Link>
            <Link className="line-after-heading">
              Barn Bonus Plus
            </Link>
          </div>
        </BreadCrumb>
      </Section>
      <BarnBenifts>
        <div className="container">
          <Congratulation barnBonusData={pageData.barnFirstSection} />
          <BarnBeniftsInner>
            <BarnBeniftsInnerItem>
              <BarnBeniftsInnerHeading className="line-after-heading">
                {pageData.barnBenefitsSection.headingText}
              </BarnBeniftsInnerHeading>
              <ListItemWrapperLeft
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.barnBenefitsSection.contentText.childMarkdownRemark
                      .html,
                }}
              />
              <TermConditions>
                {pageData.barnBenefitsSection.childrenContentfulSectionFeatureDetailsJsonNode.map(
                  (item) => {
                    return <div>{item.note}</div>;
                  }
                )}
              </TermConditions>
              <PlusCircle>
                <ImgWrapper>
                  <Image name="icon-plus.png" alt="Blog Banner" />
                </ImgWrapper>
              </PlusCircle>
            </BarnBeniftsInnerItem>
            <BarnBeniftsInnerItem>
              <BarnBeniftsInnerHeading className="line-after-heading">
                {pageData.barnOtherBenefitsSection.headingText}
              </BarnBeniftsInnerHeading>
              <ListItemWrapperRight
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.barnOtherBenefitsSection.contentText
                      .childMarkdownRemark.html,
                }}
              />

              {pageData.barnOtherBenefitsSection
                .childrenContentfulSectionFeatureDetailsJsonNode && (
                <TermConditions>
                  {pageData.barnOtherBenefitsSection.childrenContentfulSectionFeatureDetailsJsonNode.map(
                    (item) => {
                      return <div>{item.note}</div>;
                    }
                  )}
                </TermConditions>
              )}
            </BarnBeniftsInnerItem>
          </BarnBeniftsInner>
        </div>
      </BarnBenifts>
      <GradientWrapper>
        <Section
          bgColor="transparent"
          pt="100px"
          pb="70px"
          xpt="60px"
          xpb="40px"
          mpt="40px"
          mpb="40px"
        >
          <div className="container">
            <SectionTitle lgTextAlign="left">
              Apply Now to Activation Barn Bonus Plus
            </SectionTitle>
            <ActivationForm />
          </div>
        </Section>
      </GradientWrapper>
      <CustomerReviewSlider barnReviews={barnReviews} />
      <BarnBonusPlusBenifits barnBonusBenefits={pageData.barnBonusBenefits} />
    </Layout>
  );
}
export default BanBonusPlusPage;

export const query = graphql`
  query BarnBonusPage {
    contentfulBarnBonusPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      pageUrl
      barnFirstSection {
        labelText
        subHeading
        headingText
        contentText {
          contentText
        }
      }
      barnBenefitsSection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        childrenContentfulSectionFeatureDetailsJsonNode {
          note
        }
      }
      barnOtherBenefitsSection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        childrenContentfulSectionFeatureDetailsJsonNode {
          note
        }
      }
      barnBonusBenefits {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        sectionImage {
          title
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
            aspectRatio
          }
        }
      }
    }
    allContentfulReview(filter: { reviewType: { eq: "Barn Bonus Review" } }) {
      nodes {
        headingText
        reviewText {
          childMarkdownRemark {
            html
          }
        }
        fullName
        cityAndState
        reviewType
      }
    }
  }
`;
