import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowIcon, ArrowLineIcon } from "../../components/Icons";
import { Section, SectionTitle } from "../../components/Section";
import ReviewSliderBg from "../../images/testimonial-bg.jpg";
import BreakpointDown from "../../components/Media/BreakpointDown";
const SectionBorderBottom = styled(Section)`
  border-bottom: 1px #d2d8e0 solid;
  &:before {
    background-size:cover;
  }
`;
const SlickSlider = styled.div`
  position: relative;
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  ${BreakpointDown.xl`
    max-width:650px;
  `}
  ${BreakpointDown.md`
   padding-left:25px;
   padding-right:25px;
  `}
  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }
`;
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  padding: 5px 5px 0px 5px;
  font-size: 18px;
  text-align: center;
  p + p {
    margin-top: 25px;
  }
`;
const SlickArrowTrack = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
const LeftArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  box-shadow: 0px 10px 25px #143da733;
  border-radius: 5px;
  background-color: #223c7e;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${BreakpointDown.xl`
       left:15px;
    `}
  svg {
    fill: #fff;
    width: 16px;
    height: 12px;
    transform: rotate(180deg);
  }
  &:hover {
    background-color: #223c7e;
    svg {
      fill: #fff;
    }
  }
`;
const RightArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  box-shadow: 0px 10px 25px #143da733;
  border-radius: 5px;
  background-color: #223c7e;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${BreakpointDown.xl`
       right:15px;
    `}
  svg {
    fill: #fff;
    width: 16px;
    height: 12px;
    transform: rotate(0deg);
  }
  &:hover {
    background-color: #223c7e;
    svg {
      fill: #fff;
    }
  }
`;
const ReviewAuther = styled.div`
  font-weight: bold;
  font-size: 20px;
`;
const AutherLocation = styled.div`
  font-size: 18px;
  line-height: 18px;
`;

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <ArrowIcon />
    </button>
  );
}
function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <ArrowIcon />
    </button>
  );
}
export default class CustomerStoryCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      slidesToShow: 1,
      infinite: true,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: false,
      speed: 400,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false,
            centerMode: true,
            centerPadding: "0",
            infinite: true,
          },
        },
      ],
    };
    return (
      <SectionBorderBottom
        bgColor="#d9eaff"
        pt="100px"
        pb="100px"
        xpt="60px"
        xpb="60px"
        bgBefore={`url(${ReviewSliderBg})`}
      >
        <div className="container">
          <SectionTitle mb="0px">Customers reviews</SectionTitle>
          { this.props.barnReviews.nodes.length > 1 ? (
          <SlickArrowTrack>
            <LeftArrow onClick={this.previous}>
              <ArrowLineIcon />
            </LeftArrow>
            <RightArrow onClick={this.next}>
              <ArrowLineIcon />
            </RightArrow>
            </SlickArrowTrack>
          ):null
  }
          <SlickSlider>
            <Slider {...settings} ref={(c) => (this.slider = c)}>
              {this.props.barnReviews.nodes.map((item) => {
                return (
                  <SlickItem>
                    <ReviewAuther>{item.headingText}</ReviewAuther>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.reviewText.childMarkdownRemark.html,
                      }}
                    />
                    <ReviewAuther>{item.fullName}</ReviewAuther>
                    <AutherLocation>{item.cityAndState}</AutherLocation>
                  </SlickItem>
                );
              })}
            </Slider>
          </SlickSlider>
        </div>
      </SectionBorderBottom>
    );
  }
}
