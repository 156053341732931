import React, { useState } from "react";
import Img from "gatsby-image";
import styled, { keyframes } from "styled-components";
import { Section, SectionTitle } from "../../components/Section";
import { ArrowLineIcon } from "../../components/Icons";
import BreakpointDown from "../../components/Media/BreakpointDown";

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  ${BreakpointDown.lg`
    flex-wrap:wrap;
 `}
  &:before {
    content: "";
    height: 2px;
    display: block;
    background-image: linear-gradient(
      to right,
      rgb(33 31 31 / 54%) 38%,
      rgba(171, 124, 124, 0) 0%
    );
    background-position: top;
    background-size: 14px 1.4px;
    background-repeat: repeat-x;
    position: absolute;
    left: -69px;
    right: 0;
    top: 50%;
    width: 110%;
    @media (max-width: 1440px) {
      width: 105%;
      left: -30px;
    }
    ${BreakpointDown.xl`
      display:none;
    `}
  }
`;
const TabItems = styled.div`
  border: 1px solid #cbd1e2;
  border-radius: 5px;
  background: #fff;
  padding: 10px 20px;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  flex: 1;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  @media (max-width: 1440px) {
    font-size: 15px;
    padding: 6px 20px;
  }
  ${BreakpointDown.lg`
    flex: 1 1 49%;
    max-width: 49%;
    margin-bottom:15px;
  `}
  ${BreakpointDown.sm`
    flex: 1 1 100%;
    max-width: 100%;
  `}
   svg {
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    fill: #fff;
    margin-left: 15px;
    position: absolute;
    right: 0px;
  }
  &.active {
    box-shadow: 0px 10px 25px rgba(255, 76, 0, 0.3);
    border-radius: 5px;
    background: #f36224;
    border: 1px solid #f36224;
    color: #fff;
  }
  & + & {
    margin-left: 20px;
    ${BreakpointDown.xl`
    margin-left: 10px;
   `}
    ${BreakpointDown.lg`
    margin-left:0px;  
  `}
  }
`;
const TabItemsInner = styled.span`
  position: relative;
  display: inline-block;
  padding-right: 25px;
`;

const TabContent = styled.div`
  animation-duration: 1s;
  animation-name: ${FadeIn};
  animation-fill-mode: both;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  margin: 65px 0;
  transition: opacity 0.15s linear;
  ${BreakpointDown.lg`
    flex-wrap:wrap;
  `}
  ${BreakpointDown.sm`
      margin-left:0px;
      margin-right:0px;
  `}
`;

const TabContentLeft = styled.div`
  flex: 0 0 45%;
  width: 45%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.sm`
    flex:0 0 100%;
    width:100%;
    margin-bottom:20px;
    padding-left:0px;
   padding-right:0px;
  `}
`;

const TabContentRight = styled.div`
  flex: 0 0 55%;
  width: 55%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.sm`
    flex:0 0 100%;
    width:100%;
    padding-left:0px;
   padding-right:0px;
  `}
`;
const TabContentInnerItems = styled.div`
  h5, h4, h3, h2, h1 {
    margin-bottom:15px;
    font-size:28px;
    position: relative;
  &:after {
    content: "";
    display: inline-block;
    width: 36px;
    height: 2px;
    background: #f36224;
    vertical-align: middle;
    position: relative;
    margin-left: 10px;
  }

  @media (max-width: 767px) {
    &:after {
      display: none;
    }
  }
  }
  & + & {
    margin-top: 40px; 
  }
  ul, p{ margin-bottom:40px}
`;



const ImgWrapper = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  position: sticky;
  top: 160px;
  ${BreakpointDown.lg`
    postion:inherit;
    max-width:300px;
  `}
  ${BreakpointDown.sm`
    margin-left:auto;
    margin-right:auto;
  `}
`;

function BarnBonusPlusBenifits({ barnBonusBenefits }) {
  const [activeTab, setactiveTab] = useState(0);
  const HandleTabs = (key) => {
    setactiveTab(key);
  };

  return (
    <Section pt="100px" pb="200px" xpt="60px" xpb="60px">
      <div className="container">
        <SectionTitle>Barn Bonus Plus Benefits</SectionTitle>
        <TabsWrapper>
          {barnBonusBenefits.map((item, i) => (
            <TabItems
              className={`${activeTab === i ? "active" : ""}`}
              onClick={() => HandleTabs(i)}
            >
              <TabItemsInner>
                {item.headingText}
                <ArrowLineIcon />
              </TabItemsInner>
            </TabItems>
          ))}
        </TabsWrapper>
        {barnBonusBenefits.map((item, i) => (
          <>
            {activeTab === i && (
              <TabContent>
                <TabContentLeft>
                  <ImgWrapper>
                    <Img
                      fluid={item.sectionImage.fluid}
                      alt={item.sectionImage.title}
                    />
                  </ImgWrapper>
                </TabContentLeft>
                <TabContentRight>
                  <TabContentInnerItems
                    dangerouslySetInnerHTML={{
                      __html: item.contentText.childMarkdownRemark.html,
                    }}
                  />
                </TabContentRight>
              </TabContent>
            )}
          </>
        ))}
      </div>
    </Section>
  );
}

export default BarnBonusPlusBenifits;
