import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryBtn = styled(BaseButton)`
	background: transparent;
	border-color: transparent;
	color:#223C7E;
	.text{
			text-decoration:underline;
			& + .icon{
				margin-left:0;
				width:28px;	
			}
		}
	& .icon{
		background: transparent;
		margin-left:0;
		
		> svg{
			fill:#223C7E;
		}
	}
	&:hover{	
		color:#000;
		& .icon{
			background: transparent;
			> svg{
				fill:#223C7E;
			}
		}
	}
`

const PrimaryButton = (props) => {
	const { icon, text } = props;
	return(
		<PrimaryBtn className='btn'>
			<span className='text'>{text}</span><span className='icon'>{icon}</span>
		</PrimaryBtn>
	)
}

export default PrimaryButton