import React from "react";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { navigate } from "gatsby";
import { ArrowLineIcon } from "../../components/Icons";
import styled from "styled-components";
import ArroDownImg from "../../images/arrow-down.svg";
import BreakpointDown from "../../components/Media/BreakpointDown";
import encode from "../../utils/encode";

const FormWrapper = styled.div`
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
  .form-flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    .form-group {
      padding-left: 15px;
      padding-right: 15px;
      &:first-child,
      &:last-child,
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        width: 100%;
        max-width: 100%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 100%;
        max-width: 50%;
        ${BreakpointDown.sm`
            width:100%;
            max-width:100%;
				`}
      }
    }
    .form-action {
      justify-content: center;
      text-align: center;
    }
  }
  select {
    border: 0;
    border: 1px solid #cbd1e2;
    border-radius: 5px;
    background: #fff;
    padding: 20px 30px;
    width: 100%;
    appearance: none;
    position: relative;
    background: #fff url(${ArroDownImg}) no-repeat !important;
    background-position: 96% 59% !important;
    &:focus {
      border-color: #cbd1e2;
      outline: none;
    }
  }
`;
const FormLabel = styled.label`
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  color: #121316;
  margin-bottom: 10px;
  display: block;
`;

const RadioButton = styled.div`
  display: inline-block;
  position: relative;
  & + & {
    margin-left: 20px;
  }
  .check-mark {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid #cbd1e2;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    transition: all 0.2s ease;
    &:after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #f36224;
      border-radius: 100%;
      opacity: 0;
      transform: scale(0);
      transition: all 0.2s ease;
    }
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    &:checked + .check-mark {
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;

const CurrentRto = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
`;
const CurrentRtoLeft = styled.div`
  flex: 0 0 40%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.xl`
    flex:0 0 45%;
  `}
  ${BreakpointDown.sm`
    flex:0 0 100%;
  `}
`;

const CurrentRtoRight = styled.div`
  flex: 0 0 60%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.xl`
  flex:0 0 55%;
  `}
`;
const SmallText = styled.div`
  display: block;
  font-size: 14px;
  color: #121316;
`;

class ActivationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: "",
      email: "",
      mobile_no: "",
      zipcode: "",
      state: "",
      current_rto: "No",
      month_contract: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = {
      full_name: this.state.full_name,
      email: this.state.email,
      mobile_no: this.state.mobile_no,
      zipcode: this.state.zipcode,
      state: this.state.state,
      current_rto: this.state.current_rto,
      month_contract: this.state.month_contract,
    };

    const postUrl = "/?t=" + Math.floor(Date.now() / 1000);
    fetch(postUrl, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "Harsh",
    })
      .then(() => {
        navigate(form.getAttribute("action"));
        console.log(
          encode({
            "form-name": form.getAttribute("name"),
            ...data,
          })
        );
      })
      .catch((error) => alert(error));
  }
  render() {
    return (
      <FormWrapper>
        <form
          className="form-flex"
          id="rightform"
          onSubmit={this.handleSubmit}
          name="BarnBonusForm"
          method="POST"
          action="/thank-you"
          data-netlify="true"
          netlify
        >
          <input type="hidden" name="form-name" value="BarnBonusForm" />
          <div className="form-group">
            <FormLabel>Full Name</FormLabel>
            <input
              type="text"
              id="full_name"
              name="full_name"
              className="form-control"
              placeholder="Full Name *"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <FormLabel>Email Address</FormLabel>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Email Address"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <FormLabel>Phone Number</FormLabel>
            <input
              type="tel"
              id="mobile_no"
              name="mobile_no"
              className="form-control"
              placeholder="Phone Number *"
              onChange={this.handleChange}
              pattern="[0-9]{10}"
              required
            />
          </div>
          <div className="form-group">
            <FormLabel>State</FormLabel>
            <select
              id="state"
              name="state"
              className="form-control"
              onChange={this.handleChange}
            >
              <option value="---">Select State</option>
              <option value="Alabama">Alabama</option>
              <option value="Alaska">Alaska</option>
              <option value="Arizona">Arizona</option>
              <option value="Arkansas">Arkansas</option>
              <option value="California">California</option>
              <option value="Colorado">Colorado</option>
              <option value="Connecticut">Connecticut</option>
              <option value="Delaware">Delaware</option>
              <option value="District of Columbia">District of Columbia</option>
              <option value="Florida">Florida</option>
              <option value="Georgia">Georgia</option>
              <option value="Guam">Guam</option>
              <option value="Hawaii">Hawaii</option>
              <option value="Idaho">Idaho</option>
              <option value="Illinois">Illinois</option>
              <option value="Indiana">Indiana</option>
              <option value="Iowa">Iowa</option>
              <option value="Kansas">Kansas</option>
              <option value="Kentucky">Kentucky</option>
              <option value="Louisiana">Louisiana</option>
              <option value="Maine">Maine</option>
              <option value="Maryland">Maryland</option>
              <option value="Massachusetts">Massachusetts</option>
              <option value="Michigan">Michigan</option>
              <option value="Minnesota">Minnesota</option>
              <option value="Mississippi">Mississippi</option>
              <option value="Missouri">Missouri</option>
              <option value="Montana">Montana</option>
              <option value="Nebraska">Nebraska</option>
              <option value="Nevada">Nevada</option>
              <option value="New Hampshire">New Hampshire</option>
              <option value="New Jersey">New Jersey</option>
              <option value="New Mexico">New Mexico</option>
              <option value="New York">New York</option>
              <option value="North Carolina">North Carolina</option>
              <option value="North Dakota">North Dakota</option>
              <option value="Northern Marianas Islands">
                Northern Marianas Islands
              </option>
              <option value="Ohio">Ohio</option>
              <option value="Oklahoma">Oklahoma</option>
              <option value="Oregon">Oregon</option>
              <option value="Pennsylvania">Pennsylvania</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Rhode Island">Rhode Island</option>
              <option value="South Carolina">South Carolina</option>
              <option value="South Dakota">South Dakota</option>
              <option value="Tennessee">Tennessee</option>
              <option value="Texas">Texas</option>
              <option value="Utah">Utah</option>
              <option value="Vermont">Vermont</option>
              <option value="Virginia">Virginia</option>
              <option value="Virgin Islands">Virgin Islands</option>
              <option value="Washington">Washington</option>
              <option value="West Virginia">West Virginia</option>
              <option value="Wisconsin">Wisconsin</option>
              <option value="Wyoming">Wyoming</option>
            </select>
          </div>
          <div className="form-group">
            <FormLabel>Zip Code</FormLabel>
            <input
              id="zipcode"
              name="zipcode"
              className="form-control"
              onChange={this.handleChange}
              placeholder="Zipcode *"
              pattern="[0-9]*"
              required
            />
          </div>
          <div className="form-group">
            <CurrentRto>
              <CurrentRtoLeft>
                <FormLabel>
                  Do you have any current RTO plan?
                  <SmallText>(if no, select interested plan)</SmallText>
                </FormLabel>
              </CurrentRtoLeft>
              <CurrentRtoRight>
                <RadioButton>
                  <input
                    type="radio"
                    value="Yes"
                    name="rto-plan"
                    id="current_rto"
                    checked={this.state.current_rto === "Yes"}
                    onChange={this.handleChange}
                  />
                  <label className="check-mark" htmlFor="Yes">
                    {""}
                  </label>
                  Yes
                </RadioButton>
                <RadioButton>
                  <input
                    type="radio"
                    value="No"
                    name="rto-plan"
                    checked={this.state.current_rto === "No"}
                    onChange={this.handleChange}
                    id="current_rto"
                  />
                  <label className="check-mark" htmlFor="No">
                    {""}
                  </label>
                  No
                </RadioButton>
              </CurrentRtoRight>
            </CurrentRto>
          </div>

          <div className="form-group">
            <select
              id="month_contract"
              name="month_contract"
              className="form-control"
              onChange={this.handleChange}
            >
              <option sected>Select Contract</option>
              <option value="24">24 Month Contract</option>
              <option value="36">36 Month Contract</option>
              <option value="48">48 Month Contract</option>
              <option value="60">60 Month Contract</option>
            </select>
          </div>

          <div className="form-group">
            <div className="form-action">
              <button type="submit">
                <SecondaryButton text="Apply Now" icon={<ArrowLineIcon />} />
              </button>
            </div>
          </div>
        </form>
      </FormWrapper>
    );
  }
}

export default ActivationForm;
