import React from "react";
import { Section, SectionTitle } from "../../components/Section";
import Image from "../../components/image";
import styled from "styled-components";
import PrimarySecondary from "../../components/Button/SecondaryButton";
import PrimaryButtonLink from "../../components/Button/PrimaryLinkButton";
import BgImg from "../../images/congratulation-bg.jpg";
import { ArrowLineIcon } from "../../components/Icons";
import BreakpointDown from "../../components/Media/BreakpointDown";
import BreakpointUp from "../../components/Media/BreakpointUp";
import { Link } from "gatsby";

const WrapperCogratulation = styled.div`
  text-align: center;
`;
const Heading = styled(SectionTitle)`
  border: 1px solid #707070;
  padding: 10px;
  color: #121316;
  background: #fff;
  text-transform: capitalize;
  margin-bottom: 15px;
  padding: 5px;
`;
const ImgWrapper = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  ${BreakpointDown.lg`
      max-width:450px;
  `}
  ${BreakpointDown.md`
      max-width:350px;
  `}
   ${BreakpointDown.sm`
        margin-top: 10px;
      max-width: 350px;
      display: inline-block;
      width: 100%;
  `}
`;
const SubHeading = styled.div`
  color: #fff; 
`;
const ParaContent = styled.div`
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before,
  &:after {
    content: "";
    width: 98px;
    height: 2px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.54) 38%,
      rgba(171, 124, 124, 0) 0%
    );
    background-position: top;
    background-size: 14px 1.4px;
    background-repeat: repeat-x;
    @media (max-width: 1050px) {
      display: none;
    }
  }
  &:before {
    margin-right: 20px;
  }
  &:after {
    margin-left: 20px;
  }
`;
const BtnWrapper = styled.div`
  justify-content: center;
  margin-top: 32px;
  ${BreakpointUp.md`
  flex-direction: row-reverse;
    display:flex;
  `}
  .btn {
    flex: 0 0 auto;
    width: auto;
    &[class*="PrimaryLinkButton"] {
      color: #fff;
      margin-right: 30px;
      ${BreakpointDown.sm`
      margin-right:0;
    `}
      .icon {
        svg {
          fill: #fff;
        }
      }
      &:hover {
        color: #f36224;
        .icon {
          svg {
            fill: #f36224;
          }
        }
      }
    }
  }
`;

const RibbinWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -1px;
  max-width: 152px;
  width: 100%;
  margin-top: -60px;
  @media (max-width: 1050px) {
    max-width: 100px;
  }
`;
const RibbinItem = styled.div`
  background: #f36224;
  padding: 30px 5px 20px;
  color: #fff;
  text-align: center;
  ${BreakpointDown.lg`
     padding: 10px 5px 8px;
 `}
  ${BreakpointDown.sm`
     padding: 5px 5px 5px;
 `}
`;
const RibbinFlag = styled.div`
  display: flex;
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-left: 76px solid #f36224;
    border-bottom: 30px solid transparent;
    @media (max-width: 1050px) {
      border-left: 50px solid #f36224;
      border-bottom: 15px solid transparent;
    }
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-right: 76px solid #f36224;
    border-bottom: 30px solid transparent;
    @media (max-width: 1050px) {
      border-right: 50px solid #f36224;
      border-bottom: 15px solid transparent;
    }
  }
`;
const Price = styled.div`
  font-size: 50px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  @media (max-width: 1050px) {
    font-size: 30px;
  }
`;
const PerMonth = styled.div`
  font-size: 21px;
  margin-top: 5px;
  @media (max-width: 1050px) {
    margin-top: 0px;
    line-height: 20px;
  }
`;

function Congratulation({ barnBonusData }) {
  return (
    <Section
      bgImg={`url(${BgImg})`}
      bgSize="cover"
      pt="60px"
      pb="80px"
      mpb="40px"
    >
      <div className="container">
        <RibbinWrapper>
          <RibbinItem>
            <Price>${barnBonusData.labelText}</Price>
            <PerMonth>/Month</PerMonth>
          </RibbinItem>
          <RibbinFlag></RibbinFlag>
        </RibbinWrapper>
        <WrapperCogratulation>
          <ImgWrapper>
            <Image name="congratulation-bg.png" alt="Marcus Rentals" />
          </ImgWrapper>
          <Heading maxWidth="752px">{barnBonusData.subHeading}</Heading>
          <SubHeading className="h3">{barnBonusData.headingText}</SubHeading>
          <ParaContent>{barnBonusData.contentText.contentText}</ParaContent>
          <BtnWrapper>
            {barnBonusData.buttonText && (
              <Link to={barnBonusData.buttonUrl}>
                <PrimarySecondary
                  text={barnBonusData.buttonText}
                  icon={<ArrowLineIcon />}
                />
              </Link>
            )}
            {barnBonusData.linkText && (
              <Link to={barnBonusData.linkUrl}>
                <PrimaryButtonLink
                  text={barnBonusData.linkText}
                  icon={<ArrowLineIcon />}
                />
              </Link>
            )}
          </BtnWrapper>
        </WrapperCogratulation>
      </div>
    </Section>
  );
}
export default Congratulation;
